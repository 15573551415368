<div class="loyalty-card-yalty-time-series-stats-component default-srolling-design" style="max-width: 97%;">
    <div class="state-and-filters-container">
        <h2>{{ currentState }}
            <i class="fa fa-chevron-right" style="font-size:80%"></i> {{ currentSubState }}
        </h2>
        <form [formGroup]="filtersForm" class="filters-form" [hidden]='loyaltyCardTemplates.length < 2 && stores.length < 2 && (allStoresResult == null || allStoresResult.wasStampOrRedeemInDeletedStore == false) && (allStoresResult == null || allStoresResult.wasStampOrRedeemWithoutStore == false)'>
            <app-multiple-select-all inputName="loyaltyCardFilter" label="Hűségkártya" [objArray]="loyaltyCardTemplates"
                class="gray-1-theme" allOptionDisplayName="Mind">
            </app-multiple-select-all>
            <mat-form-field appearance="standard">
                <mat-label style="line-height: 1.2;">Üzlet</mat-label>
                <mat-select formControlName="storeFilter" multiple required>
                    <mat-select-trigger>{{storeFilterDisplayValue}}</mat-select-trigger>
                    <mat-checkbox color="primary" class="in-select option-separated"
                        [formControl]="activeStoreFilterField" matRipple>Csak aktív üzletek megjelenítése</mat-checkbox>
                    <mat-option [value]="'all'">Mind</mat-option>
                    <mat-option [value]="'deleted'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemInDeletedStore">Törölt üzletek
                    </mat-option>
                    <mat-option [value]="'virtual'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemWithoutStore && !activeStoreFilter">
                        Online vagy telefonos rendelések (Nincs üzlet)</mat-option>
                    <mat-option *ngFor="let store of stores" [value]="store.value">{{store.display}}</mat-option>
                </mat-select>
                <mat-error *ngIf="storeFilterField.hasError('required')">Mező kitöltése kötelező!</mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="update(true)" class="update-button" style="border-radius: 6px;"
                [disabled]="initApiCallFinished == false || waitingForAPIResult || (adminUserType=='' && currentSubType != 'Egyedi' && currentSubType != 'Premium') || (adminUserType!='' && adminUserType!='executive') || ((adminUserType=='' || adminUserType=='executive') && (loyaltyCardTemplates.length == 0 || stores.length == 0) && (currentSubType == 'Egyedi' || currentSubType == 'Premium'))">
                <span [hidden]="initApiCallFinished == false || waitingForAPIResult">Frissítés</span>
                <img *ngIf="initApiCallFinished == false || waitingForAPIResult" class="loading" src="imgs/loading_indicator.gif">
            </button>
        </form>
    </div>

    <div *ngIf="(adminUserType=='' || adminUserType=='executive') && currentSubType != 'Egyedi' && currentSubType != 'Premium'" class="stat-container">
        <p>Ez az oldal idősoros grafikonon mutatja meg egy tetszőlegesen kiválasztott időszakra az egyedi és új ügyfelek, az egyedi és új kártyák és a pecsételések/beváltások időbeni változását napi, heti vagy havi bontásban.
        <br>
        <br>
        Az adatok megtekintéséhez <b>{{reqSubType}}</b> előfizetés szükséges. Önnek jelenleg <b>{{currentSubType}}</b> előfizetése van. <a href="partner/home/partner-subscription" style="color: #00AEEF; text-decoration: none;">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>
        </p>
    </div>
    
    <div *ngIf="adminUserType!='' && adminUserType!='executive'" class="stat-container">
        <p>Adatbiztonsági okokból Yalty admin nem látja és nem kezelheti partnereink felhasználóinak adatait.</p>
    </div>

    <div *ngIf="initApiCallFinished == true && (adminUserType=='' || adminUserType=='executive') && (loyaltyCardTemplates.length == 0 || stores.length == 0) && (currentSubType == 'Egyedi' || currentSubType == 'Premium')" class="stat-container">
        <p>Még nincs aktív hűségkártyája vagy a kártyát még egy ügyfele sem használta. Az Időszaki grafikonok akkor jelennek meg, ha legalább egy kártyán legalább egy pecsételés történt.</p>
    </div>

    <div>
        <form [formGroup]="timeSeriesForm" class="time-series-form" style="text-align: center; margin-top: 20px;" *ngIf="(adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
            <app-select id="period" inputName="period" label="Időszak" [objArray]="periodOptions" class="gray-1-theme">
            </app-select>
            <app-date-picker id="periodStartTimestamp" inputName="periodStartTimestamp" label="Időszak -Tól" [disabled]="timeSeriesPeriod !== 6"
                *ngIf="timeSeriesPeriod !== 1">
            </app-date-picker>
            <app-date-picker id="periodEndTimestamp" inputName="periodEndTimestamp" label="Időszak -Ig" [disabled]="timeSeriesPeriod !== 6"
                *ngIf="timeSeriesPeriod !== 1">
            </app-date-picker>
            <app-select id="aggregationType" inputName="aggregationType" label="Vizsgált időszak egység" [objArray]="aggregationTypeOptions"
                class="gray-1-theme">
            </app-select>
            <button mat-flat-button color="primary" (click)="update(true)" class="update-button" style="border-radius: 6px;"
                [disabled]="waitingForAPIResult || initApiCallFinished != true || loyaltyCardTemplates.length == 0 || stores.length == 0">
                <span [hidden]="waitingForAPIResult">Frissítés</span>
                <img *ngIf="waitingForAPIResult" class="loading" src="imgs/loading_indicator.gif">
            </button>
        </form>

        <form [formGroup]="displayedStatsForm" style="text-align: center; margin-top: 20px;" *ngIf="showLCYDashboardTimeSeriesStats && timeSeriesChartDisplayable == true && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
            <div style="display: flex; justify-content: space-between;">
                <mat-form-field appearance="standard" style="width: 250px; margin-left: 55px;">
                    <mat-label style="line-height: 1.2;">Megjelenített statisztikák</mat-label>
                    <mat-select formControlName="displayedStats" multiple>
                        <mat-optgroup label="Ügyfelek">
                            <mat-option [value]="'totalNrOfUsers'">Egyedi ügyfelek</mat-option>
                            <mat-option [value]="'nrOfNewUsers'">Új ügyfelek</mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Kártyák">
                            <mat-option [value]="'totalNrOfInstances'">Egyedi kártyák</mat-option>
                            <mat-option [value]="'nrOfNewInstances'">Új kártyák</mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Pecsételés/beváltások">
                            <mat-option [value]="'totalNrOfStamps'">Pecsételések</mat-option>
                            <mat-option [value]="'totalNrOfRedeems'">Beváltások</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
                <div style="align-self: center; margin-right: 150px;">
                    <div style="display: flex; justify-content: space-between; width: 60px;">
                        <a (click)="setChartType('line')"><img [src]="chartType == 'line' ? '../../../imgs/line_area.png' : '../../../imgs/line_area_inactive.png'" style="width: 20px; height: 20px; cursor: pointer;"></a>
                        <a (click)="setChartType('bar')"><img [src]="chartType == 'bar' ? '../../../imgs/bar.png' : '../../../imgs/bar_inactive.png'" style="width: 20px; height: 20px; cursor: pointer;"></a>
                    </div>
                </div>
            </div>
        </form>

        <div *ngIf="initApiCallFinished == false || waitingForAPIResult" style="width: 100%; text-align: -webkit-center;">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>

        <div *ngIf="showLCYDashboardTimeSeriesStats && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium') && timeSeriesChartDisplayable == false" class="stat-container">
            <p>A beállított szűrések mellett nincs megjeleníthető adat.</p>
        </div>

        <div *ngIf="showLCYDashboardTimeSeriesStats && timeSeriesChartDisplayable == true && chartType == 'line' && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
            <canvas baseChart height="350" [datasets]="timeSeriesChartDatasets" [labels]="timeSeriesChartLabels"
                [options]="timeSeriesChartOptions" [colors]="timeSeriesChartColors" [legend]="timeSeriesChartLegend"
                [chartType]="timeSeriesChartType" [plugins]="timeSeriesChartPlugins">
            </canvas>
        </div>
        <div *ngIf="showLCYDashboardTimeSeriesStats && timeSeriesChartDisplayable == true && chartType == 'bar' && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
            <canvas baseChart height="350" [datasets]="timeSeriesChartDatasets" [labels]="timeSeriesChartLabels"
                [options]="timeSeriesChartOptions" [colors]="timeSeriesChartColors" [legend]="timeSeriesChartLegend"
                [chartType]="timeSeriesChartType2" [plugins]="timeSeriesChartPlugins">
            </canvas>
        </div>
    </div>
    <div *ngIf="showLCYDashboardTimeSeriesStats && timeSeriesChartDisplayable == true && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
        <h2  style="margin-top: 55px; margin-bottom: 0px;">Összesített számok a kiválasztott időszakra</h2>
        <div style="display: flex; justify-content: space-between; margin-bottom: 40px; flex-wrap: wrap;">
            <div style="margin-right: 15px;">
                <h3>Ügyfél számosság</h3>
                <table>
                    <tbody>
                        <tr>    
                          <th>Egyedi ügyfelek</th>
                          <td>{{ timeSeriesDataSummary.totalNrOfUsers }}</td>
                        </tr>
                        <tr>
                            <th>Új ügyfelek</th>
                            <td>{{ timeSeriesDataSummary.nrOfNewUsers }}</td>
                        </tr>
                    </tbody>
                    </table>
            </div>
            <div style="margin-right: 15px;">
                <h3>Kártya számosság</h3>
                <table>
                    <tbody>
                        <tr>    
                            <th>Egyedi kártyák</th>
                            <td>{{ timeSeriesDataSummary.totalNrOfInstances }}</td>
                        </tr>
                        <tr>
                            <th>Új kártyák</th>
                            <td>{{ timeSeriesDataSummary.nrOfNewInstances }}</td>
                        </tr>
                    </tbody>
                    </table></div>
            <div>
                <h3>Pecsételés/beváltás számosság</h3>
                <table>
                    <tbody>
                        <tr>    
                            <th>Pecsételések</th>
                            <td>{{ timeSeriesDataSummary.totalNrOfStamps }}</td>
                        </tr>
                        <tr>
                            <th>Beváltások</th>
                            <td>{{ timeSeriesDataSummary.totalNrOfRedeems }}</td>
                        </tr>
                    </tbody>
                </table></div>
        </div>
    </div>
</div>