// @ts-nocheck
const bwipjs = require('bwip-js');
const QRCode = require('qrcode');
const gs1_128 = bwipjs['gs1_128'];

angular.module('yaltyApp')

    .component('thirdPartyLoyaltyCardDetailView', {
        templateUrl: 'js/components/thirdPartyLoyaltyCardDetailView.html?v=202501141010',
        controller: function () {
            var ctrl = this;
            ctrl.$onChanges = function (changes) {

                if (changes.cardBackgroundColor) {
                    if (ctrl.cardBackgroundColor == '#FFFFFF') {
                        ctrl.rewardStampedBackgroundColor = { 'background-color': '#00AEEF' }
                        ctrl.rewardUnstampedBackgroundColor = { 'background-color': '#DFDFDF' }
                        ctrl.rewardStampedIconColor = { 'fill': '#8B8B8E' }
                        ctrl.rewardUnstampedIconColor = { 'fill': '#FFFFFF' }
                    } else {
                        ctrl.rewardStampedBackgroundColor = {
                            'background-color': 'rgba(139, 139, 142, 0.7)'
                        }
                        ctrl.rewardUnstampedBackgroundColor = {
                            'background-color': 'rgba(139, 139, 142, 0.3)'
                        }
                        ctrl.rewardStampedIconColor = { 'fill': '#00AEEF' }
                        ctrl.rewardUnstampedIconColor = { 'fill': '#FFFFFF' }
                    }
                }

                ctrl.getFontSize = function (text) {
                    if (text != undefined) {
                        const length = text.length;
                        if (40 - length > 28) {
                            return '30px';
                        }
                        else if (40 - length < 15) {
                            return '15px';
                        }
                        return 40 - length + 'px';
                    } else {
                        return '30px';
                    }
                };

                function barcodeNumberSpacing(numberStr) {
                    const groups = [];
                    if (ctrl.customerLoyaltyIdGroupingType == 0) {
                        return numberStr;
                    } else if (ctrl.customerLoyaltyIdGroupingType == 1) {
                        if (numberStr.length <= 5) {
                            return numberStr.toString();
                        } else {
                            if (numberStr.length % 4 === 0) {
                                for (let i = 0; i < numberStr.length; i += 4) { groups.push(numberStr.slice(i, i + 4)); }
                            } else if (numberStr.length % 3 === 0) {
                                for (let i = 0; i < numberStr.length; i += 3) { groups.push(numberStr.slice(i, i + 3)); }
                            } else {
                                for (let i = 0; i < numberStr.length; i += 4) { groups.push(numberStr.slice(i, i + 4)); }
                            }
                            return groups.join(' ');
                        }
                    } else if (ctrl.customerLoyaltyIdGroupingType == 2) {
                        if (ctrl.customerLoyaltyIdGroups == undefined || ctrl.customerLoyaltyIdGroups == null) {
                            return numberStr;
                        }
                        for (let i = 0; i < numberStr.length; i += ctrl.customerLoyaltyIdGroups) {
                            groups.push(numberStr.slice(i, i + ctrl.customerLoyaltyIdGroups));
                        }
                        return groups.join(' ');
                    }
                }

                // Canvas elem lekérése
                var canvas = document.getElementById('barcode-canvas');
                var canvas2 = document.getElementById('qrcode-canvas');

                if (ctrl.barcodeType == 1) {
                    // Vonalkód rajzolása a canvasra
                    try {
                        var barcodeType = 'code128';
                        var barcodeScale = 2.0;
                        var barcodeHeight = 15;
                        var barcodeText = ctrl.sampleCustomerLoyaltyId;
                        switch (ctrl.barcodeSubType) {
                            case 21:
                                barcodeType = 'ean13';
                                barcodeScale = 2;
                                if (ctrl.sampleCustomerLoyaltyId.length != 13) {
                                    throw "Invalid barcode lenght!";
                                }
                                break;
                            case 20:
                                barcodeType = 'ean8'
                                barcodeScale = 2;
                                if (ctrl.sampleCustomerLoyaltyId.length != 8) {
                                    throw "Invalid barcode lenght!";
                                }
                                break;
                            case 27:
                                barcodeType = 'gs1-128'
                                barcodeScale = 1.0;
                                barcodeHeight = 30;
                                break;
                            case 22:
                                barcodeType = 'interleaved2of5'
                                barcodeScale = 2;
                                ctrl.barcodeNumber = barcodeNumberSpacing('00012345678905');
                                break;
                            case 23:
                                barcodeType = 'code39'
                                barcodeScale = 1;
                                var barcodeHeight = 30;
                                break;
                            case 24:
                                barcodeType = 'code128'
                                break;
                            case 25:
                                barcodeType = 'upca'
                                if (ctrl.sampleCustomerLoyaltyId.length != 12) {
                                    throw "Invalid barcode lenght!";
                                }
                                break;
                            case 26:
                                barcodeType = 'upce'
                                barcodeScale = 2;
                                break;
                        }
                        ctrl.barcodeNumber = barcodeNumberSpacing(ctrl.sampleCustomerLoyaltyId.toString());
                        ctrl.isBarcodeInvalidFormat = false;
                        bwipjs.toCanvas(canvas, {
                            bcid: barcodeType,       // Vonalkód típusa
                            text: barcodeText,    // Vonalkód tartalma
                            scale: barcodeScale,              // Skála (méret)
                            height: barcodeHeight,            // Magasság
                        });
                    } catch (e) {
                        ctrl.sampleCustomerLoyaltyId == undefined || ctrl.sampleCustomerLoyaltyId == '' ? ctrl.isBarcodeInvalidFormat = false : ctrl.isBarcodeInvalidFormat = true;
                        switch (ctrl.barcodeSubType) {
                            case 21:
                                ctrl.barcodeNumber = barcodeNumberSpacing('1234567890128');
                                barcodeText = '1234567890128';
                                break;
                            case 20:
                                ctrl.barcodeNumber = barcodeNumberSpacing('12344567');
                                barcodeText = '12344567';
                                break;
                            case 27:
                                ctrl.barcodeNumber = barcodeNumberSpacing('(01)01234567890128(17)240112');
                                barcodeText = '(01)01234567890128(17)240112';
                                break;
                            case 22:
                                ctrl.barcodeNumber = barcodeNumberSpacing('123456789012');
                                barcodeText = '123456789012';
                                break;
                            case 23:
                                ctrl.barcodeNumber = barcodeNumberSpacing('12345678');
                                barcodeText = '12345678';
                                break;
                            case 24:
                                ctrl.barcodeNumber = barcodeNumberSpacing('1234567890');
                                barcodeText = '1234567890';
                                break;
                            case 25:
                                ctrl.barcodeNumber = barcodeNumberSpacing('725272730706');
                                barcodeText = '725272730706';
                                break;
                            case 26:
                                ctrl.barcodeNumber = barcodeNumberSpacing('012345000058');
                                barcodeText = '012345000058';
                                break;
                        }
                        try{
                            bwipjs.toCanvas(canvas, {
                                bcid: barcodeType,       // Vonalkód típusa
                                text: barcodeText,    // Vonalkód tartalma
                                scale: barcodeScale,              // Skála (méret)
                                height: barcodeHeight,            // Magasság
                            });
                        }catch(e){
                            console.log(e);
                        }
                    }
                } else if (ctrl.barcodeType == 10) {
                    ctrl.isBarcodeInvalidFormat = false;
                    // Generate QR code on canvas
                    if (ctrl.sampleCustomerLoyaltyId == undefined || ctrl.sampleCustomerLoyaltyId == '') {
                        ctrl.barcodeNumber = barcodeNumberSpacing('1234567');
                        QRCode.toCanvas(canvas2, '1234567', {
                            width: 86, // Set the size of the QR code
                            margin: 0,  // Set the margin around the QR code
                            color: {
                                dark: '#000000',  // Dark module color (QR code itself)
                                light: '#ffffff'  // Light module color (background)
                            }
                        }, function (err) {
                        });
                    } else {
                        QRCode.toCanvas(canvas2, ctrl.sampleCustomerLoyaltyId, {
                            width: 86, // Set the size of the QR code
                            margin: 0,  // Set the margin around the QR code
                            color: {
                                dark: '#000000',  // Dark module color (QR code itself)
                                light: '#ffffff'  // Light module color (background)
                            }
                        }, function (err) {
                            if (err) {
                                ctrl.barcodeNumber = barcodeNumberSpacing('1234567');
                                ctrl.isBarcodeInvalidFormat = true;
                                QRCode.toCanvas(canvas2, '1234567', {
                                    width: 86, // Set the size of the QR code
                                    margin: 0,  // Set the margin around the QR code
                                    color: {
                                        dark: '#000000',  // Dark module color (QR code itself)
                                        light: '#ffffff'  // Light module color (background)
                                    }
                                }, function (err) {
                                });
                            } else {
                                ctrl.barcodeNumber = barcodeNumberSpacing(ctrl.sampleCustomerLoyaltyId.toString());
                            }
                        });
                    }

                }



            }
        },
        bindings: {
            detailedDisplayBackgroundColor: '<',
            cardBackgroundColor: '<',
            cardLogoImgURL: '<',
            compNamePromo: '<',
            compNamePromoTextColor: '<',
            //stampTextPlaceholderColor: '<',
            //stampCurrentCountTextColor: '<',
            //floorNrOfStampsNeeded: '<',
            //sumNrOfStampsNeeded: '<',
            promotionNameTextColor: '<',
            promotionName: '<',
            //selectedTemplateImg: '<',
            //stampCropperData: '<',
            //unstampCropperData: '<',
            //stampedStyle: '<',
            //unstampedStyle: '<',
            //sizedStampStyle: '<',
            //sizedUnstampStyle: '<',
            //showStampIcon: '<',
            //stampIconType: '<',
            //isStampedColorEnabled: '<',
            //isUnstampedColorEnabled: '<',
            //stampIconStampedColor: '<',
            //stampIconUnstampedColor: '<',
            //stampConditionsDescr: '<',
            //reward1NrOfStampsNeeded: '<',
            //reward2NrOfStampsNeeded: '<',
            //reward3NrOfStampsNeeded: '<',
            //reward4NrOfStampsNeeded: '<',
            //reward5NrOfStampsNeeded: '<',
            //reward1RewardDescr: '<',
            //reward2RewardDescr: '<',
            //reward3RewardDescr: '<',
            //reward4RewardDescr: '<',
            //reward5RewardDescr: '<',
            //noOfRewards: '<',
            //isOnlineOrderAvailable: '<',
            //isPhoneOrderAvailable: '<',
            //onlineOrderInfoMessage: '<',
            //phoneOrderInfoMessage: '<',
            //maxNoOfDaysBetweenStampingText: '<',
            //cardValidityDurationText: '<',
            //promotionConditionsDescr: '<',
            nrOfCompanyStores: '<',
            detailPreviewIconsColor: '<',
            //baseStampStampedFillColor: '<',
            //isYLCPartnerRedeemRemarkEnabled: '<',
            showEntireContent: '<',
            isNumber: '&',
            showUsageGuideSection: '<',
            usageGuide: '<',
            showNotesSection: '<',
            showCardImagesSection: '<',
            detailViewBarcodeColor: '<',
            barcodeSubType: '<',
            customerLoyaltyIdGroupingType: '<',
            customerLoyaltyIdGroups: '<',
            sampleCustomerLoyaltyId: '<',
            isBarcodeInvalidFormat: '=',
            barcodeType: '<',
            barcodeNumber: '='
        }
    })/*.filter('removeDayPartFromValidity', () => {
        return (input) => {
            if (!input) return
            if (input.includes('év') || input.includes('hónap')) {
                return input.split('(')[0]
            } else return input
        }
    });*/