import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { forkJoin, merge, Subject, Subscription } from 'rxjs';
import { GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse } from 'src/app/shared/interfaces/stores';
import { SelectInput } from 'src/app/shared/interfaces/input';
import { HttpService } from 'src/app/shared/services/http.service';
import { CookieService } from 'ngx-cookie-service';
import {
    GetAllLoyaltyCardsListViewDataRequest,
    GetAllLoyaltyCardsListViewDataResponse,
    GetLCYAnalyticsDashboardStatsDataRequest,
    GetLCYAnalyticsUserStatsDataRequest,
    GetLCYAnalyticsUserStatsDataResponse,
    LCYAnalyticsDashboardStats,
    LCYAnalyticsUserStats
} from 'src/app/shared/interfaces/loyalty-cards';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePeriodService } from 'src/app/shared/services/date-period.service';
import { MultipleSelectService } from 'src/app/shared/services/multiple-select.service';
import { MultipleSelect } from 'src/app/shared/classes/multiple-select';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { MatRipple } from '@angular/material/core';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ChartService } from 'src/app/shared/services/chart.service';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import searchTextHL from 'search-text-highlight'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-loyalty-card-yalty-user-stats',
    templateUrl: './loyalty-card-yalty-user-stats.component.html',
    styleUrls: ['./loyalty-card-yalty-user-stats.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoyaltyCardYaltyUserStatsComponent implements OnInit {

    currentState = 'KIMUTATÁSOK';
    currentSubState = 'Ügyfél lista';
    reqSubType = 'Premium';
    currentSubType: string | undefined;
    adminUserType = this.cookieService.get('adminUserType');

    filtersForm: FormGroup;
    orderByForm: FormGroup;
    activeStoreFilterField: FormControl;
    detailedViewField: FormControl;

    activeFilterSet = false;
    selectedFileType: string = 'Excel';

    quickSearchPlaceholderText: string = 'Keresés: Ügyfél név, azonosító';
    quickSearchText: string = '';
    loyaltyCardTemplates: SelectInput[] = [];
    stores: SelectInput[] = [];
    getAllLoyaltyCardsListViewDataRequest: GetAllLoyaltyCardsListViewDataRequest = {
        getFilteredLCsByMarketingPackage: false
    }
    getLCYAnalyticsDashboardStatsDataRequest: GetLCYAnalyticsDashboardStatsDataRequest;
    getLCYAnalyticsUserStatsDataRequest: GetLCYAnalyticsUserStatsDataRequest;
    lCYDashboardStats: LCYAnalyticsDashboardStats;
    lCYAnalyticsUserStats: LCYAnalyticsUserStats[];
    filteredUserStats: LCYAnalyticsUserStats[] = [];
    allStores$ = this.httpService.get<GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse>('/company/getAllCompanyStoreDataForLCYDashboardStoreSelector');
    allLoyaltyCardTemplate$ = this.httpService.post<GetAllLoyaltyCardsListViewDataResponse>('/loyaltyCard/getAllLoyaltyCardStampListViewData', this.getAllLoyaltyCardsListViewDataRequest);
    allInitAPICall$ = forkJoin([this.allStores$, this.allLoyaltyCardTemplate$]);
    allStoresResult: GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse
    hasAnyLoyaltyCardWithMoreThanOneRedeem = false;
    hasAnyLoyaltyCardWithEnabledYLCSelectableProducts = false;
    initApiCallFinished = false;
    showLCYDashboardStats = false;
    showLCYUserStats = false;
    timezone = '';
    nrOfFilteredLoyaltyCardTemplates = 0;
    totalNrOfActiveStores = 0;
    storeFilterDisplayValue: string | undefined;
    periodOptions = this.datePeriodService.periodOptions;
    multipleSelect = new MultipleSelect();
    resizeListContainer = new Subject<void>();
    storeListHeight: object;
    resizeObservable$ = merge(this.commonService.resizeObservable$, this.resizeListContainer.pipe(debounceTime(100)));
    loyaltyCards: GetAllLoyaltyCardsListViewDataResponse["allLoyaltyCardsListViewData"];
    isContactInformationBeingAsked = false;
    isPhoneContactBeingAsked = false;
    isEmailContactBeingAsked = false;
    savedLoyaltyCardFilterStates: SectionStates = {
        dashboard: [],
        time: [],
        other: [],
        customer: []
    }
    savedStoreFilterStates: SectionStates = {
        dashboard: [],
        time: [],
        other: [],
        customer: []
    }
    globalLoyaltyCardFilter: (string | number)[];
    globalStoreFilter: (string | number)[];
    aggregationTypeOptions: SelectInput[] = [
        { value: 1, display: 'Napi' },
        { value: 2, display: 'Heti' },
        { value: 3, display: 'Havi' }];

    @ViewChild(MatRipple) ripple: MatRipple;
    waitingForAPIResult = false;

    private subscriptions: Subscription[] = [];

    defaultFiltersFormValues = {
        loyaltyCardFilter: null,
        storeFilter: null
    };
    defaultSectionSelectorValue = 'dashboard'
    defaultActiveStoreFilterValue = true;
    defaultUserStatsFormValues = {
        period: 7,
        periodStartTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).fromTimestamp,
        periodEndTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).endTimestamp,
        aggregationType: 1
    };


    constructor(
        private httpService: HttpService,
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
        private cookieService: CookieService,
        private datePeriodService: DatePeriodService,
        private multipleSelectService: MultipleSelectService,
        private formValidatorService: FormValidatorService,
        private commonService: CommonService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        if(this.cookieService.get('sbcrtyp').includes('yalty_start_free')) {
            this.currentSubType = 'Yalty Start'
        } else if(this.cookieService.get('sbcrtyp').includes('yalty_start_paid')) {
            this.currentSubType = 'Yalty Start'
        } else if(this.cookieService.get('sbcrtyp').includes('basic')) {
            this.currentSubType = 'Design+'
        } else if(this.cookieService.get('sbcrtyp').includes('premium')) {
            this.currentSubType = 'Premium'
        } else if(this.cookieService.get('sbcrtyp').includes('custom')) {
            this.currentSubType = 'Egyedi'
        }
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.filtersForm = this.formBuilder.group({
            loyaltyCardFilter: [this.defaultFiltersFormValues.loyaltyCardFilter, Validators.required],
            storeFilter: [this.defaultFiltersFormValues.storeFilter, Validators.required]
        });
        this.activeStoreFilterField = new FormControl(this.defaultActiveStoreFilterValue);
        this.detailedViewField = new FormControl(false);
        this.orderByForm = this.formBuilder.group({
            orderBy: ['nrOfStamps'],
            detailView: [true]
        });
        this.subscriptions.push(
            this.allInitAPICall$.subscribe(results => {
                this.initApiCallFinished = true;
                this.allStoresResult = results[0];
                const loyaltyCards = results[1];
                this.loyaltyCards = results[1].allLoyaltyCardsListViewData;
                this.updateStores(this.activeStoreFilter);
                this.storeFilterField.setValue(['all']);
                for (let i = 0; i < loyaltyCards.allLoyaltyCardsListViewData.length; i++) {
                    if(loyaltyCards.allLoyaltyCardsListViewData[i].reward2NrOfStampsNeeded != null) {
                        this.hasAnyLoyaltyCardWithMoreThanOneRedeem = true;
                    }
                    if(loyaltyCards.allLoyaltyCardsListViewData[i].isYLCSelectableProductsEnabled == true) {
                        this.hasAnyLoyaltyCardWithEnabledYLCSelectableProducts = true;
                    }
                    if (loyaltyCards.allLoyaltyCardsListViewData[i].loyaltyCardPromoStatus !== 0) {
                        const promoStatus = loyaltyCards.allLoyaltyCardsListViewData[i].loyaltyCardPromoStatus;
                        const promotionName = loyaltyCards.allLoyaltyCardsListViewData[i].promotionName
                        this.loyaltyCardTemplates.push({
                            value: loyaltyCards.allLoyaltyCardsListViewData[i].id,
                            display: this.getPromotionNameDisplayValue(promoStatus, promotionName)
                        });
                    }
                }
                this.loyaltyCardFilterField.setValue(['all']);
                setTimeout(() => {
                    // SetTimeout 0 needed because this.loyaltyCardFilterField.setValue(['all']) going to tick off all options
                    // but it takes time, because options not rendered yet and it's work async.
                    // In case of store it works, because it's a locally declared not a component from other files which using data bindings.   
                    //this.savedLoyaltyCardFilterStates[this.sectionSelector] = this.loyaltyCardFilter;
                    this.globalLoyaltyCardFilter = this.loyaltyCardFilter;
                    if(this.loyaltyCardTemplates.length > 0 && this.stores.length > 0 && (this.adminUserType=='' || this.adminUserType=='executive') && (this.currentSubType == 'Egyedi' || this.currentSubType == 'Premium')) {
                        this.update();
                    }
                }, 0);
                //this.savedStoreFilterStates[this.sectionSelector] = this.storeFilter;
                this.globalStoreFilter = this.storeFilter;
            }),
            this.storeFilterField.valueChanges.subscribe(() => {
                this.updateStoreFilters();
            }),
            this.orderByField.valueChanges.subscribe(() => {
                if(this.orderByField.value == 'userLifeTime') {
                    this.filteredUserStats = [...this.filteredUserStats].sort((a, b) => b.userLifeTime - a.userLifeTime);
                } else if(this.orderByField.value == 'nrOfStamps') {
                    this.filteredUserStats = [...this.filteredUserStats].sort((a, b) => b.nrOfStamps - a.nrOfStamps);
                } else if(this.orderByField.value == 'nrOfRedeemedFinalRewards') {
                    this.filteredUserStats = [...this.filteredUserStats].sort((a, b) => b.nrOfRedeemedFinalRewards - a.nrOfRedeemedFinalRewards);
                } else if(this.orderByField.value == 'nrOfRedeemedInstances') {
                    this.filteredUserStats = [...this.filteredUserStats].sort((a, b) => b.nrOfRedeemedInstances - a.nrOfRedeemedInstances);
                } else if(this.orderByField.value == 'nrOfActiveLoyaltyCardYaltyInstances') {
                    this.filteredUserStats = [...this.filteredUserStats].sort((a, b) => b.activeLoyaltyCardYaltyInstances.length - a.activeLoyaltyCardYaltyInstances.length);
                }
            }),
            this.activeStoreFilterField.valueChanges.subscribe(val => {
                this.updateStores(val);
                this.updateStoreFilters(true);
            }),
            this.resizeObservable$.subscribe(evt => {
                this.setUserStatsListHeight();
            })
        );
    }

    setUserStatsListHeight() {
        setTimeout(() => {
            const globalFiltersContainer = this.commonService.getAbsoluteHeightOfElement('state-and-filters-container', false);
            const topHeader = 52;
            const bottomPadding = 20;
            const orderSection = 72;
            if (!globalFiltersContainer) return;
            const windowsHeight = window.innerHeight - topHeader - globalFiltersContainer - bottomPadding - orderSection;
            this.storeListHeight = { 'height': windowsHeight + 'px' };
        }, 0);
    }

    getPromotionNameDisplayValue(promoStatus: number, promotionName: string): string {
        if (promoStatus == 1) return `${promotionName} (aktív)`
        else if (promoStatus == 2) return `${promotionName} (kifuttatott)`
        else if (promoStatus == 3) return `${promotionName} (deaktivált)`
        else return ''
    }

    setSearchHighlight(text: string): SafeHtml {
        const options = { htmlTag: 'label', hlClass: 'search-text-highlite-class', caseSensitive: false };
        const escapedSearchText = this.escapeRegExp(this.quickSearchText);
        const highlightedText = searchTextHL.highlight(text, escapedSearchText, options);
        return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
      }
      
    private escapeRegExp(str: string): string {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
    updateStores(active: boolean) {
        const currentStores: SelectInput[] = [];
        for (let i = 0; i < this.allStoresResult.companyStores.length; i++) {
            let needToAdd = false;
            if (active) needToAdd = this.allStoresResult.companyStores[i].stampOrRedeemHappened;
            else needToAdd = true;
            if (needToAdd) currentStores.push({
                value: this.allStoresResult.companyStores[i].id,
                display: this.allStoresResult.companyStores[i].compStoreName
            });
        }
        this.stores = currentStores;
    }

    updateStoreFilters(activeFilterChanged?: boolean) {
        let all = false, deleted = false, virtual = false;
        const active = this.activeStoreFilter;
        for (let i = 0; i < this.storeFilter.length; i++) {
            if (all && deleted && virtual) break;
            if (this.storeFilter[i] === 'all') all = true;
            else if (this.storeFilter[i] === 'deleted') deleted = true;
            else if (this.storeFilter[i] === 'virtual' && !active) virtual = true;
        }
        const visibleStores = this.stores.map(store => store.value);
        if (all) visibleStores.unshift('all');
        let selectedStores;
        if (all && activeFilterChanged) {
            selectedStores = visibleStores;
        } else {
            const visibleSelectedStores = this.storeFilter.filter(store => visibleStores.includes(store));
            selectedStores = this.multipleSelect.getOptions(
                this.stores, visibleSelectedStores, all);
        }
        if (deleted) selectedStores.unshift('deleted');
        if (virtual) selectedStores.unshift('virtual');
        this.storeFilterField.setValue(selectedStores, { emitEvent: false });
        this.updateStoreDisplayValue(all, deleted, virtual);

    }

    updateStoreDisplayValue(all: boolean, deleted: boolean, virtual: boolean) {
        const stores = this.stores.filter(store => this.storeFilter.includes(store.value));
        const displayValues = stores.map(o => o.display);
        if (all) displayValues.push('all');
        if (virtual) displayValues.unshift('Online vagy telefonos rendelések (Nincs üzlet)');
        if (deleted) displayValues.unshift('Törölt üzletek');
        this.storeFilterDisplayValue = this.multipleSelectService.getDisplayValue(
            displayValues
        ) || 'Mind';
    }

    saveGlobalFilters() {
        this.globalLoyaltyCardFilter = this.loyaltyCardFilter;
        this.globalStoreFilter = this.storeFilter;
    }

    setNrOfActiveStores() {
        let n = this.stores.length + this.allStoresResult.deletedCompanyStoreIds.length;
        if (this.allStoresResult.wasStampOrRedeemWithoutStore && !this.activeStoreFilter) n++;
        this.totalNrOfActiveStores = n;
    }

    getDeletedOptionSelected(updateButtonPressed: boolean): boolean {
        if (updateButtonPressed) return this.storeFilter.includes('deleted');
        else return this.globalStoreFilter.includes('deleted');
    }

    getVirtualOptionSelected(updateButtonPressed: boolean): boolean {
        if (updateButtonPressed) return this.storeFilter.includes('virtual');
        else return this.globalStoreFilter.includes('virtual');
    }

    getStoresForRequestParams(deletedOptionSelected: boolean, virtualOptionSelected: boolean, updateButtonPressed: boolean): (string | number)[] {
        let stores = [];
        if (updateButtonPressed) stores = this.storeFilter.filter(store => store !== 'all' && store !== 'virtual' && store !== 'deleted');
        else  stores = this.globalStoreFilter.filter(store => store !== 'all' && store !== 'virtual' && store !== 'deleted');
        if (deletedOptionSelected) stores.push(...this.allStoresResult.deletedCompanyStoreIds);
        if (virtualOptionSelected) stores.push(0);
        return stores;
    }

    getLoyaltyCardsForRequestParams(updateButtonPressed: boolean): (string | number)[] {
        let loyaltyCards = [];
        if (updateButtonPressed) loyaltyCards = this.loyaltyCardFilter.filter(card => card !== 'all');
        else  loyaltyCards = this.globalLoyaltyCardFilter.filter(card => card !== 'all');
        return loyaltyCards;
    }

    update(updateButtonPressed: boolean = false) {
        if (!this.filtersForm.valid) {
            this.formValidatorService.validateAllFormFields(this.filtersForm);
        } else {
            this.getUserStatsData(updateButtonPressed);
        }
    }

    checkContactInformationNeeded(updateButtonPressed: boolean){
        this.quickSearchPlaceholderText = 'Keresés: Ügyfél név, azonosító';
        this.isEmailContactBeingAsked = false;
        this.isPhoneContactBeingAsked = false;
        this.isContactInformationBeingAsked = false;
        for (let i = 0; i < this.getLoyaltyCardsForRequestParams(updateButtonPressed).length; i++) {
            if(this.loyaltyCards.find(obj => obj.id === this.getLoyaltyCardsForRequestParams(updateButtonPressed)[i])?.isEmailContactInfoRequired != null){
                this.isEmailContactBeingAsked = true;
                this.isContactInformationBeingAsked = true;
            }
            if(this.loyaltyCards.find(obj => obj.id === this.getLoyaltyCardsForRequestParams(updateButtonPressed)[i])?.isPhoneContactInfoRequired != null){
                this.isPhoneContactBeingAsked = true;
                this.isContactInformationBeingAsked = true;
            }
        }
        this.isEmailContactBeingAsked ? this.quickSearchPlaceholderText += ", email" : null;
        this.isPhoneContactBeingAsked ? this.quickSearchPlaceholderText += ", telefonszám" : null;
    }

    getUserStatsData(updateButtonPressed: boolean) {
        this.checkContactInformationNeeded(updateButtonPressed);
        this.waitingForAPIResult = true;
        this.showLCYUserStats = false;
        const deletedOptionSelected = this.getDeletedOptionSelected(updateButtonPressed);
        const virtualOptionSelected = this.getVirtualOptionSelected(updateButtonPressed);
        const stores = this.getStoresForRequestParams(deletedOptionSelected, virtualOptionSelected, updateButtonPressed);
        this.nrOfFilteredLoyaltyCardTemplates = this.getLoyaltyCardsForRequestParams(updateButtonPressed).length;
        this.getLCYAnalyticsUserStatsDataRequest = {
            loyaltyCardYaltyIds: this.getLoyaltyCardsForRequestParams(updateButtonPressed),
            companyStoreIds: stores,
            isVirtualStoreSelected: virtualOptionSelected,
            hasAnyLoyaltyCardWithEnabledYLCSelectableProducts: this.hasAnyLoyaltyCardWithEnabledYLCSelectableProducts,
            // Ha a Felhasználói (aktivitás alapú) szegmensek mutató ki lesz vezetve, akkor
            // az alábbi start és end dátumokat tölteni a mutató képzési logikájában leírtak szerint
            periodStartTimestamp: null,
            periodEndTimestamp: null,
            frontendTimezone: 'Central European Standard Time'
        };
        this.httpService.post<GetLCYAnalyticsUserStatsDataResponse>('/partner/getLCYAnalyticsUserStatsData', this.getLCYAnalyticsUserStatsDataRequest)
            .subscribe((resp: GetLCYAnalyticsUserStatsDataResponse) => {
                if (resp.errorCode === 0) {
                    if (updateButtonPressed) this.saveGlobalFilters();
                    this.savedLoyaltyCardFilterStates['customer'] = this.globalLoyaltyCardFilter;
                    this.savedStoreFilterStates['customer'] = this.globalStoreFilter;
                    this.lCYAnalyticsUserStats = resp.userStatsData;
                    this.filteredUserStats = this.lCYAnalyticsUserStats;
                    if(this.orderByField.value == 'userLifeTime') {
                        this.filteredUserStats.sort((a, b) => b.userLifeTime - a.userLifeTime);
                    } else if(this.orderByField.value == 'nrOfStamps') {
                        this.filteredUserStats.sort((a, b) => b.nrOfStamps - a.nrOfStamps);
                    } else if(this.orderByField.value == 'nrOfRedeemedFinalRewards') {
                        this.filteredUserStats.sort((a, b) => b.nrOfRedeemedFinalRewards - a.nrOfRedeemedFinalRewards);
                    } else if(this.orderByField.value == 'nrOfRedeemedInstances') {
                        this.filteredUserStats.sort((a, b) => b.nrOfRedeemedInstances - a.nrOfRedeemedInstances);
                    } else if(this.orderByField.value == 'nrOfActiveLoyaltyCardYaltyInstances') {
                        this.filteredUserStats.sort((a, b) => b.activeLoyaltyCardYaltyInstances.length - a.activeLoyaltyCardYaltyInstances.length);
                    }
                    this.waitingForAPIResult = false;
                    this.showLCYUserStats = true;
                    this.setUserStatsListHeight();
                } else {
                    this.waitingForAPIResult = false;
                    this.dialogService.openDialog('Szerver hiba. Kérjük próbálja meg később.',
                        '', 'Rendben', undefined, '450px');
                }
            });
    }
    
    exportData(){
        let users: User[] = [];
        for (let i = 0; i < this.filteredUserStats.length; i++) {
            users[i] = {
                lastName: (this.filteredUserStats[i].firstName == null && this.filteredUserStats[i].lastName == null ) ? "Törölt felhasználó" : this.filteredUserStats[i].lastName,
                firstName: (this.filteredUserStats[i].firstName == null && this.filteredUserStats[i].lastName == null ) ? "-" : this.filteredUserStats[i].firstName,
                publicRedeemUserId: this.filteredUserStats[i].publicRedeemUserId,
                contacts: "",
                firstActivityTimestamp: new Date(this.filteredUserStats[i].firstActivityTimestamp),
                lastActivityTimestamp: new Date(this.filteredUserStats[i].lastActivityTimestamp),
                userLifeTime: ((((this.filteredUserStats[i].userLifeTime + 1) - ((this.filteredUserStats[i].userLifeTime + 1)) % 365)) / 365 + " év " +Math.round((this.filteredUserStats[i].userLifeTime + 1) % 365) +" nap").toString(),
                userLifeTimeInDays: Math.round(this.filteredUserStats[i].userLifeTime + 1),
                nrOfStamps: this.filteredUserStats[i].nrOfStamps,
                activeLoyaltyCardYaltyInstances: this.filteredUserStats[i].activeLoyaltyCardYaltyInstances.length,
                nrOfRedeemedInstances: this.filteredUserStats[i].nrOfRedeemedInstances,
                nrOfExpiredInstances: this.filteredUserStats[i].nrOfExpiredInstances,
                nrOfRemovedInstances: this.filteredUserStats[i].nrOfRemovedInstances
            };
            if(this.filteredUserStats[i].firstName == null && this.filteredUserStats[i].lastName == null ){
                users[i].contacts = "Törölt felhasználó";
            }
            else if(this.filteredUserStats[i].emailContact.length == 0 && this.filteredUserStats[i].phoneContact.length == 0 && this.filteredUserStats[i].customerContactInfoRegistrationTimestamp.length > 0){
                users[i].contacts = "Nem adta meg.";
            }
            else if(this.filteredUserStats[i].emailContact.length == 0 && this.filteredUserStats[i].phoneContact.length == 0 && this.filteredUserStats[i].customerContactInfoRegistrationTimestamp.length == 0){
                users[i].contacts = "Még nem adta meg.";
            }
            else{
                for (let k = 0; k < this.filteredUserStats[i].emailContact.length; k++) {
                    users[i].contacts += this.filteredUserStats[i].emailContact[k];
                    k < this.filteredUserStats[i].emailContact.length-1 ? users[i].contacts += ", " : null;
                }
                if(users[i].contacts != "" && this.filteredUserStats[i].phoneContact.length > 0){
                    users[i].contacts += ", "
                }
                for (let j = 0; j < this.filteredUserStats[i].phoneContact.length; j++) {
                    users[i].contacts += this.filteredUserStats[i].phoneContact[j];
                    j < this.filteredUserStats[i].phoneContact.length-1 ? users[i].contacts += ", " : null;
                }
            }
        }
        
        if(this.selectedFileType == 'Excel'){
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Ügyfél lista');

            worksheet.columns = [
                { header: 'Vezetéknév', key: 'lastName', width: 20 },
                { header: 'Keresztnév', key: 'firstName', width: 20 },
                { header: 'Yalty publikus azonosító', key: 'publicRedeemUserId', width: 25 },
                { header: 'Kapcsolat', key: 'contacts', width: 25 },
                { header: 'Első aktivitás', key: 'firstActivityTimestamp', width: 25 },
                { header: 'Utolsó aktivitás', key: 'lastActivityTimestamp', width: 25 },
                { header: 'Ügyfél élettartam', key: 'userLifeTime', width: 25 },
                { header: 'Ügyfél élettartam (napokban)', key: 'userLifeTimeInDays', width: 30 },
                { header: 'Pecsétek (db)', key: 'nrOfStamps', width: 20},
                { header: 'Aktív kártyák (db)', key: 'activeLoyaltyCardYaltyInstances', width: 25 },
                { header: 'Beváltott kártyák (db)', key: 'nrOfRedeemedInstances', width: 25 },
                { header: 'Lejárt kártyák (db)', key: 'nrOfExpiredInstances', width: 20 },
                { header: 'Törölt kártyák (db)', key: 'nrOfRemovedInstances', width: 20 }
            ];

            if(this.isContactInformationBeingAsked == false){
                worksheet.autoFilter = {
                    from: 'A1',
                    to: 'L1',
                };
            }else{
                worksheet.autoFilter = {
                    from: 'A1',
                    to: 'M1',
                };
            }

            switch(this.orderByField.value){
                case "userLifeTime":
                    users.sort((a, b) => b.userLifeTimeInDays - a.userLifeTimeInDays);
                break;
                case "nrOfStamps":
                    users.sort((a, b) => b.nrOfStamps - a.nrOfStamps);
                break;
                case "nrOfRedeemedInstances":
                    users.sort((a, b) => b.nrOfRedeemedInstances - a.nrOfRedeemedInstances);
                break;
                case "nrOfActiveLoyaltyCardYaltyInstances":
                    users.sort((a, b) => b.activeLoyaltyCardYaltyInstances - a.activeLoyaltyCardYaltyInstances);
                break;

            }

            worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
            worksheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00aeef' }
            };

            users.forEach(user => {
                const row = worksheet.addRow({
                    lastName: user.lastName,
                    firstName: user.firstName,
                    publicRedeemUserId: user.publicRedeemUserId,
                    contacts: user.contacts,
                    firstActivityTimestamp: new Date(user.firstActivityTimestamp.getTime() - user.firstActivityTimestamp.getTimezoneOffset() * 60000),
                    lastActivityTimestamp: new Date(user.lastActivityTimestamp.getTime() - user.lastActivityTimestamp.getTimezoneOffset() * 60000),
                    userLifeTime: user.userLifeTime,
                    userLifeTimeInDays: user.userLifeTimeInDays,
                    nrOfStamps: user.nrOfStamps,
                    activeLoyaltyCardYaltyInstances: user.activeLoyaltyCardYaltyInstances,
                    nrOfRedeemedInstances: user.nrOfRedeemedInstances,
                    nrOfExpiredInstances: user.nrOfExpiredInstances,
                    nrOfRemovedInstances: user.nrOfRemovedInstances
                });
                row.getCell('lastName').font = { bold: true };
                row.getCell('firstName').font = { bold: true };
                if (user.lastName === 'Törölt felhasználó') {
                    row.getCell('firstName').font = { italic: true };
                    row.getCell('lastName').font = { italic: true };
                }
                if (user.contacts === 'Törölt felhasználó' || user.contacts === 'Még nem adta meg.' || user.contacts === 'Nem adta meg.') {
                    row.getCell('contacts').font = { italic: true };
                }
                row.getCell('firstActivityTimestamp').numFmt = 'yyyy.mm.dd hh:mm';
                row.getCell('lastActivityTimestamp').numFmt = 'yyyy.mm.dd hh:mm';
            });

            const contactsColumn = worksheet.getColumn('contacts');
            let maxLength = 0;

            worksheet.eachRow({ includeEmpty: true }, (row) => {
                const cellValue = row.getCell('contacts').value;
                if (cellValue) {
                    const length = String(cellValue).length;
                    maxLength = Math.max(maxLength, length);
                }
            });
    
            contactsColumn.width = maxLength;
            
            worksheet.getCell('G1').note = 'Az első és utolsó aktivitás közt eltelt idő.';
            worksheet.getCell('H1').note = 'Az első és utolsó aktivitás közt eltelt idő napokban.';
            worksheet.getCell('I1').note = 'Hány pecsétet gyűjtött eddig össze összesen (darab).';
            worksheet.getCell('K1').note = 'Az összes olyan eddigi kártyák száma (darab), amelyeken minden pecsét kitelt és minden jutalom beváltásra került.';
            worksheet.getCell('J1').note = 'Az olyan jelenleg is aktív, nyitott kártyák száma (darab), amelyeken van legalább egy pecsét és amelyek még érvényesek, tehát egyik lejáratuk szerint sem jártak még le.';
            worksheet.getCell('L1').note = 'Az összes eddigi lejárt kártyák száma (darab).';
            worksheet.getCell('M1').note = 'Az összes eddigi ügyfél által törölt (de nem lejárt) kártyák száma (darab).';

            
            worksheet.eachRow({ includeEmpty: true }, (row) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.alignment = { horizontal: 'center', vertical: 'middle' };
                });
            });
            
            if(this.isContactInformationBeingAsked == false){
                worksheet.spliceColumns(4, 1);
            }

            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'yalty_export.xlsx');  
            }).catch(error => console.error('Error exporting Excel file:', error));
            
        }
        else if(this.selectedFileType == 'CSV'){
            let csvData = '';
            if(this.isContactInformationBeingAsked){
                csvData += 'Vezetéknév,Keresztnév,Yalty publikus azonosító,Kapcsolat,Első aktivitás,Utolsó aktivitás,Ügyfél élettartam,Ügyfél élettartam (napokban),Pecsétek (db),Aktív kártyák (db),Beváltott kártyák (db),Lejárt kártyák (db),Törölt kártyák (db)\n';
                users.forEach(user => {
                        csvData += `"${user.lastName}","${user.firstName}","${user.publicRedeemUserId}","${user.contacts}","${user.firstActivityTimestamp}","${user.lastActivityTimestamp}","${user.userLifeTime}","${user.userLifeTimeInDays}","${user.nrOfStamps}","${user.activeLoyaltyCardYaltyInstances}","${user.nrOfRedeemedInstances}","${user.nrOfExpiredInstances}","${user.nrOfRemovedInstances}"\n`;
                });
            }else{
                csvData += 'Vezetéknév,Keresztnév,Yalty publikus azonosító,Első aktivitás,Utolsó aktivitás,Ügyfél élettartam,Ügyfél élettartam (napokban),Pecsétek (db),Aktív kártyák (db),Beváltott kártyák (db),Lejárt kártyák (db),Törölt kártyák (db)\n';
                users.forEach(user => {
                        csvData += `"${user.lastName}","${user.firstName}","${user.publicRedeemUserId}","${user.firstActivityTimestamp}","${user.lastActivityTimestamp}","${user.userLifeTime}","${user.userLifeTimeInDays}","${user.nrOfStamps}","${user.activeLoyaltyCardYaltyInstances}","${user.nrOfRedeemedInstances}","${user.nrOfExpiredInstances}","${user.nrOfRemovedInstances}"\n`;
                });
            }

            // Blob létrehozása és letöltés elindítása
            const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'yalty_export.csv';
            a.style.visibility = 'hidden';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            }
  }

    filterByPartnerSearch() {
        if(this.quickSearchText == null || this.quickSearchText == ""){
            this.filteredUserStats = this.lCYAnalyticsUserStats;
        }else{
            let temp = [];
            let j = 0;
            for (let i = 0; i < this.lCYAnalyticsUserStats.length; i++) {
                if(this.lCYAnalyticsUserStats[i].firstName == null && this.lCYAnalyticsUserStats[i].lastName == null){
                    if('törölt felhasználó'.includes(this.quickSearchText.toLowerCase())){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                    if(this.lCYAnalyticsUserStats[i].publicRedeemUserId != null && this.lCYAnalyticsUserStats[i].publicRedeemUserId.toLowerCase().includes(this.quickSearchText.toLowerCase())){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                }else{
                    if(this.lCYAnalyticsUserStats[i].firstName != null && this.lCYAnalyticsUserStats[i].firstName.toLowerCase().includes(this.quickSearchText.toLowerCase())){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                    if(this.lCYAnalyticsUserStats[i].lastName != null && this.lCYAnalyticsUserStats[i].lastName.toLowerCase().includes(this.quickSearchText.toLowerCase())){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                    if(this.lCYAnalyticsUserStats[i].lastName != null && this.lCYAnalyticsUserStats[i].firstName != null && (this.lCYAnalyticsUserStats[i].lastName+" "+this.lCYAnalyticsUserStats[i].firstName).toLowerCase().includes(this.quickSearchText.toLowerCase()) ){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                if(this.lCYAnalyticsUserStats[i].publicRedeemUserId != null && this.lCYAnalyticsUserStats[i].publicRedeemUserId.toLowerCase().includes(this.quickSearchText.toLowerCase())){
                    temp[j] = this.lCYAnalyticsUserStats[i];
                    j++;
                    continue;
                }
                if(this.lCYAnalyticsUserStats[i].phoneContact.length == 0 && this.lCYAnalyticsUserStats[i].emailContact.length == 0 && this.lCYAnalyticsUserStats[i].customerContactInfoRegistrationTimestamp.length > 0 && (this.lCYAnalyticsUserStats[i].lastName != null || this.lCYAnalyticsUserStats[i].firstName != null)){
                    if('nem adta meg.'.includes(this.quickSearchText.toLowerCase())){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                }
                if(this.lCYAnalyticsUserStats[i].phoneContact.length == 0 && this.lCYAnalyticsUserStats[i].emailContact.length == 0 && this.lCYAnalyticsUserStats[i].customerContactInfoRegistrationTimestamp.length == 0 && (this.lCYAnalyticsUserStats[i].lastName != null || this.lCYAnalyticsUserStats[i].firstName != null)){
                    if('még nem adta meg.'.includes(this.quickSearchText.toLowerCase())){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                }
                if(this.isEmailContactBeingAsked){
                    if(this.lCYAnalyticsUserStats[i].emailContact != null && this.lCYAnalyticsUserStats[i].emailContact.some((item: string) => item.toLowerCase().includes(this.quickSearchText.toLowerCase()))){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                }
                if(this.isPhoneContactBeingAsked){
                    if(this.lCYAnalyticsUserStats[i].phoneContact != null && this.lCYAnalyticsUserStats[i].phoneContact.some((item: string) => item.toLowerCase().includes(this.quickSearchText.toLowerCase()))){
                        temp[j] = this.lCYAnalyticsUserStats[i];
                        j++;
                        continue;
                    }
                }
            }
            }
            this.filteredUserStats = temp;
            this.setUserStatsListHeight();
        }
    }

    get storeFilterField(): AbstractControl { return this.filtersForm.get('storeFilter')!; }
    get storeFilter(): (string | number)[] { return this.storeFilterField.value; }

    get orderByField(): AbstractControl { return this.orderByForm.get('orderBy')!; }
    get orderBy(): (string | number)[] { return this.orderByField.value; }

    get detailedView(): boolean { return this.detailedViewField.value; }

    get loyaltyCardFilterField(): AbstractControl { return this.filtersForm.get('loyaltyCardFilter')!; }
    get loyaltyCardFilter(): (string | number)[] { return this.loyaltyCardFilterField.value; }

    get activeStoreFilter(): boolean { return this.activeStoreFilterField.value; }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }

}

interface User {
    lastName: string;
    firstName: string;
    publicRedeemUserId: string;
    contacts: string;
    firstActivityTimestamp: Date;
    lastActivityTimestamp: Date;
    userLifeTime: string;
    userLifeTimeInDays: number;
    nrOfStamps:  number;
    activeLoyaltyCardYaltyInstances: number;
    nrOfRedeemedInstances:  number;
    nrOfExpiredInstances: number;
    nrOfRemovedInstances: number;
  }

interface SectionNames {
    dashboard: boolean;
    time: boolean;
    other: boolean;
    customer: boolean;
}

interface SectionStates {
    dashboard: (string | number)[];
    time: (string | number)[];
    other: (string | number)[];
    customer: (string | number)[];
}
