// @ts-nocheck
const searchTextHL = require('search-text-highlight')

angular.module('yaltyApp')
    
    .controller('LoyaltyCardYaltyRedeemsHistoryController', function($scope, $http, API_PREFIX, GoogleAnalyticsService, SubscriptionNames, $rootScope, $cookies, $sce) {
        $scope.partnerSearch = '';

        $scope.preventEnter = function(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };

        function escapeRegExp(string){
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          }

        $scope.setSearchHighlight = function(text) {
            if($scope.partnerSearch != null){
                const options = { htmlTag: 'label', hlClass: 'search-text-highlite-class', caseSensitive: false };
                const escapedSearchText = escapeRegExp($scope.partnerSearch);
                var highlightedText = searchTextHL.highlight(text, escapedSearchText, options);
                return $sce.trustAsHtml(highlightedText);
            }else{
                return text;
            }
        };
    
        let date = new Date();
        let actualYear = date.getFullYear();
        let actualMonth = date.getMonth();
        $scope.originalList = [];
        $scope.year = actualYear;
        $scope.allYear = [];
        $scope.allMonth = [];
        $scope.checkedCompanyStores = [];
        $scope.loyaltyCards = [];
        $scope.companyStores = [];
        $scope.redeemDates = [];
        $scope.allCompanyStore = [];
        $scope.allLoyaltyCard = [];
        $scope.allRedeemDate = [];
        let monthList = ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"];
        $scope.month = actualMonth + 1;
        $scope.showHistoryData = false;
        $scope.showInfo = false;
        $scope.showInfo1 = false;
        $scope.showInfo2 = false;
        $scope.showInfo3 = false;
        if(actualYear == 2019){
            for(let i = 3; i <= actualMonth; i++){
                let months = {};
                months.value = i+1;
                months.name = monthList[i];
                $scope.allMonth.push(months);
            }
        } else {
            for(let i = 0; i <= actualMonth; i++){
                let months = {};
                months.value = i+1;
                months.name = monthList[i];
                $scope.allMonth.push(months);
            }
        }
        for(let i = actualYear; i >= 2019; i--){
            let years = {};
            years.value = i;
            years.name = i;
            $scope.allYear.push(years);
        }

        $scope.changeMonth = function(){
            $scope.month = actualMonth + 1;
            $scope.allMonth = [];
            if($scope.year == 2019 && $scope.year == actualYear){
                for(let i = 2; i <= actualMonth; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else if($scope.year == 2019){
                for(let i = 2; i < monthList.length; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else if($scope.year == actualYear) {
                for(let i = 0; i <= actualMonth; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else {
                for(let i = 0; i < monthList.length; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            }
        }

        $scope.refreshList = function () {
            /*if(document.getElementById('loadingOverlay')){
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }*/
            let filters = {};
            if ($scope.year) {
                filters.year = $scope.year;
            }
            if ($scope.month) {
                filters.month = $scope.month;
            }
            $scope.adminUserType = $cookies.get('adminUserType');
            if($scope.adminUserType == 'admin' || $scope.adminUserType == 'sales' || $scope.adminUserType == 'manager' || $scope.adminUserType == 'marketing'){
                $scope.showHistoryData = false;
                $scope.showInfo = false;
                $scope.showInfo1 = false;
                $scope.showInfo2 = false;
                $scope.showInfo3 = true;
            } else if($rootScope.partnerSubscriptionType != 'yalty_start_free' && $rootScope.partnerSubscriptionType != 'yalty_start_paid' && $rootScope.partnerSubscriptionType != 'basic'){
                $scope.refreshInProgress = true;
                $scope.redeemsHistoryData = undefined;
                setListHeight();
                $scope.showInfo = false;
                $scope.showInfo1 = false;
                return $http.post(API_PREFIX.url + '/partner/getLoyaltyCardYaltyStampAndRedeemsMonthly', filters).then(function (result) {
                    $scope.refreshInProgress = false;
                    /*if(document.getElementById('loadingOverlay')){
                        document.getElementById('loadingOverlay').style.display = "none";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                    }*/
                    if (result.data.errorCode == 0) {
                        $scope.originalList = result.data.stampAndRedeemsArray;
                        $scope.redeemsHistoryData = $scope.originalList;
                        $scope.companyStores = [];
                        $scope.redeemDates = [];
                        $scope.allCompanyStore = [];
                        $scope.allLoyaltyCard = [];
                        $scope.allRedeemDate = [];
                        for(let i = 0; i < result.data.stampAndRedeemsArray.length; i++){
                            if(result.data.stampAndRedeemsArray[i].compStoreName != null){
                                let compStore = {};
                                compStore.value = result.data.stampAndRedeemsArray[i].companyStoreId;
                                compStore.name = result.data.stampAndRedeemsArray[i].compStoreName;
                                const index = $scope.allCompanyStore.findIndex(x => x.name==result.data.stampAndRedeemsArray[i].compStoreName)
                                if (index === -1){
                                    $scope.allCompanyStore.push(compStore);
                                }
                            }
                            var loyCard = {};
                            loyCard.value = result.data.stampAndRedeemsArray[i].loyaltyCardYaltyId;
                            loyCard.name = result.data.stampAndRedeemsArray[i].promotionName;
                            const index = $scope.allLoyaltyCard.findIndex(x => x.value==result.data.stampAndRedeemsArray[i].loyaltyCardYaltyId)
                            if (index === -1){
                                $scope.allLoyaltyCard.push(loyCard);
                            }
                        }
                        var redeemDateValue = -1;
                        for(let i = result.data.stampAndRedeemsArray.length - 1; i >= 0; i--){
                            var redeemDate = {};
                            var d = new Date(result.data.stampAndRedeemsArray[i].redeemStartTimestamp_CEST);
                            var datestring = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
                            redeemDate.name = datestring;
                            const index = $scope.allRedeemDate.findIndex(x => x.name==redeemDate.name)
                            if (index === -1){
                                redeemDateValue++;
                                redeemDate.value = redeemDateValue;
                                $scope.allRedeemDate.push(redeemDate);
                            }
                        }
                        for(let i = 0; i < result.data.stampAndRedeemsArray.length; i++){ //Online vagy telefonos rendelés opció hozzáadása
                            if(result.data.stampAndRedeemsArray[i].compStoreName == null){
                                let compStore = {};
                                compStore.value = 0;
                                compStore.name = "Online vagy telefonos rendelés/beváltás";
                                const index = $scope.allCompanyStore.findIndex(x => x.name=="Online vagy telefonos rendelés/beváltás")
                                if (index === -1){
                                    $scope.allCompanyStore.push(compStore);
                                }
                            }
                        }
                        $scope.checkAll = true;
                        for(let i = 0; i < $scope.allCompanyStore.length; i++){
                            $scope.companyStores.push($scope.allCompanyStore[i].value);
                        }
                        $scope.checkAllLoyaltyCard = true;
                        for(let i = 0; i < $scope.allLoyaltyCard.length; i++){
                            $scope.loyaltyCards.push($scope.allLoyaltyCard[i].value);
                        }
                        $scope.checkAllRedeemDate = true;
                        if($scope.allRedeemDate.length != 0){
                            $scope.redeemDateText = "Mind";
                        } else {
                            $scope.redeemDateText = "Beváltás napja";
                        }
                        for(let i = 0; i < $scope.allRedeemDate.length; i++){
                            $scope.redeemDates.push($scope.allRedeemDate[i].value);
                        }
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártya_beváltások_története/lista_lekérdezés_sikeres",
                            "HU Partner [PRIVÁT] - Hűségkártya beváltások története oldal - Sikeres lista lekérdezés")
                        if(result.data.stampAndRedeemsArray.length == 0){
                            $scope.showHistoryData = false;
                            $scope.showInfo = true;
                            $scope.showInfo1 = false;
                            $scope.showInfo2 = false;
                            $scope.showInfo3 = false;
                        } else {
                            $scope.showHistoryData = true;
                            $scope.showInfo = false;
                            $scope.showInfo1 = false;
                            $scope.showInfo2 = false;
                            $scope.showInfo3 = false;
                        }
                    } else {
                        $scope.redeemsHistoryData = [];
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártya_beváltások_története/lista_lekérdezés_sikertelen",
                            "HU Partner [PRIVÁT] - Hűségkártya beváltások története oldal - Sikertelen lista lekérdezés")
                        $scope.showHistoryData = false;
                        $scope.showInfo = true;
                        $scope.showInfo1 = false;
                        $scope.showInfo2 = false;
                        $scope.showInfo3 = false;
                    }
                    setListHeight();
                });
            } else {
                $scope.showHistoryData = false;
                $scope.showInfo = false;
                $scope.showInfo1 = false;
                $scope.showInfo2 = true;
                $scope.showInfo3 = false;
                $scope.currentSubType = SubscriptionNames[$rootScope.partnerSubscriptionType];
                $scope.reqSubType = SubscriptionNames['premium'];
            }
        }

        $scope.filterList = function () {
            let temp = [];
            let j = 0;
            let filteredData = [];
            for(let i = 0; i < $scope.originalList.length; i++){
                filteredData.push($scope.originalList[i]);
            }
            for(let i = 0; i < filteredData.length; i++){
                if(!$scope.companyStores.includes(filteredData[i].companyStoreId) ){
                    filteredData.splice(i, 1);
                    i = -1;
                }
            }
            for(let i = 0; i < filteredData.length; i++){
                if(!$scope.loyaltyCards.includes(filteredData[i].loyaltyCardYaltyId)){
                    filteredData.splice(i, 1);
                    i = -1;
                }
            }
            if($scope.partnerSearch != null && $scope.partnerSearch.length > 0){
                for(let i = 0; i < filteredData.length; i++){
                    if(filteredData[i].userLastName == null && filteredData[i].userFirstName == null){
                        if("törölt felhasználó".includes($scope.partnerSearch.toLowerCase())){
                            temp[j] = filteredData[i];
                            j++;
                            continue;
                        }
                    }else{
                        if(filteredData[i].userFirstName.toLowerCase().includes($scope.partnerSearch.toLowerCase()) || filteredData[i].userLastName.toLowerCase().includes($scope.partnerSearch.toLowerCase()) || (filteredData[i].userLastName+" "+filteredData[i].userFirstName).toLowerCase().includes($scope.partnerSearch.toLowerCase())){
                            temp[j] = filteredData[i];
                            j++;
                            continue;
                        }
                    }
                    }
                
                for(let i = 0; i < filteredData.length; i++){
                    if(filteredData[i].userPublicRedeemUserId.toLowerCase().includes($scope.partnerSearch.toLowerCase())){
                        temp[j] = filteredData[i];
                        j++;
                        continue;
                    }
                }
                filteredData = filteredData.filter(element => temp.includes(element));
            }
            $scope.redeemDates_seged = [];
            for(let j = 0; j < $scope.redeemDates.length; j++){
                $scope.redeemDates_seged.push($scope.allRedeemDate[$scope.redeemDates[j]].name);
            }

            for(let i = 0; i < filteredData.length; i++){
                var d = new Date(filteredData[i].redeemStartTimestamp_CEST);
                var datestring = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
                if(!$scope.redeemDates_seged.includes(datestring)){
                    filteredData.splice(i, 1);
                    i = -1;
                }
            }
            $scope.redeemsHistoryData = filteredData;
            if(filteredData.length == 0){
                $scope.showHistoryData = false;
                $scope.showInfo = false;
                $scope.showInfo1 = true;
                $scope.showInfo2 = false;
                $scope.showInfo3 = false;
            } else {
                $scope.showHistoryData = true;
                $scope.showInfo = false;
                $scope.showInfo1 = false;
                $scope.showInfo2 = false;
                $scope.showInfo3 = false;
            }
            setListHeight();
        }

        $scope.selectCompStore = function () {
            $scope.filterList();
        }

        $scope.selectLoyaltyCard = function () {
            $scope.filterList();
        }

        $scope.selectRedeemDate = function () {
            $scope.filterList();
        }

        $scope.$watch('companyStores', function (value){
            if (value) {
                if ($scope.companyStores.length == $scope.allCompanyStore.length) {
                    $scope.checkAll = true;
                } else {
                    $scope.checkAll = false;
                }
            }
        })
        $scope.$watch('loyaltyCards', function (value){
            if (value) {
                if ($scope.loyaltyCards.length == $scope.allLoyaltyCard.length) {
                    $scope.checkAllLoyaltyCard = true;
                } else {
                    $scope.checkAllLoyaltyCard = false;
                }
            }
        })
        $scope.$watch('redeemDates', function (value){
            if (value) {
                if ($scope.redeemDates.length == $scope.allRedeemDate.length) {
                    $scope.checkAllRedeemDate = true;
                    if($scope.allRedeemDate.length != 0){
                        $scope.redeemDateText = "Mind";
                    } else {
                        $scope.redeemDateText = "Beváltás napja";
                    }
                } else {
                    $scope.checkAllRedeemDate = false;
                    if($scope.redeemDates.length != 0){
                        $scope.redeemDates_seged = [];
                        for(let j = 0; j < $scope.redeemDates.length; j++){
                            $scope.redeemDates_seged.push($scope.allRedeemDate[$scope.redeemDates[j]].name);
                        }
                        $scope.redeemDateText = "";
                        for(let k = 0; k < $scope.redeemDates_seged.length; k++){
                            $scope.redeemDateText += $scope.redeemDates_seged[k];
                            if((k + 1) != $scope.redeemDates_seged.length){
                                $scope.redeemDateText += ", ";
                            }
                        }
                    } else {
                        $scope.redeemDateText = "Beváltás napja";
                    }
                }
            }
        })
        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "companyStores") {
                    $scope.companyStores = [];
                    for(let i = 0; i < $scope.allCompanyStore.length; i++){
                        $scope.companyStores.push($scope.allCompanyStore[i].value);
                    }
                } else if(model == "redeemDates"){
                    for(let i = 0; i < $scope.allRedeemDate.length; i++){
                        $scope.redeemDates.push($scope.allRedeemDate[i].value);
                    }
                    if($scope.allRedeemDate.length != 0){
                        $scope.redeemDateText = "Mind";
                    } else {
                        $scope.redeemDateText = "Beváltás napja";
                    }
                } else {
                    $scope.loyaltyCards = [];
                    for(let i = 0; i < $scope.allLoyaltyCard.length; i++){
                        $scope.loyaltyCards.push($scope.allLoyaltyCard[i].value);
                    }
                }
            } else {
                if (model == "companyStores") {
                    $scope.companyStores = [];
                } else if(model == "redeemDates"){
                    $scope.redeemDates = [];
                } else {
                    $scope.loyaltyCards = [];
                }
            }
            $scope.filterList();
        }
        function setListHeight(){
            if($scope.showInfo2 == false && $scope.showInfo2 != undefined){
                if($scope.redeemsHistoryData != undefined){
                    if($scope.redeemsHistoryData.length == 0){
                        let tablazatFejlecHeight = document.getElementById('tablazatFejlec').offsetHeight;
                        let filterFejlecHeight = document.getElementById('filterFejlec').offsetHeight;
                        $scope.listHeight = {'height': tablazatFejlecHeight + filterFejlecHeight + 10 + 'px'};
                    } else {
                        let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                        let filterHeight = document.getElementById('filterDiv').offsetHeight;
                        let windowsHeight = window.innerHeight - currentStateHeight - filterHeight - 20;
                        $scope.listHeight = {'height': (windowsHeight + 35) + 'px'};
                    }
                } else {
                    let tablazatFejlecHeight = document.getElementById('tablazatFejlec').offsetHeight;
                    let filterFejlecHeight = document.getElementById('filterFejlec').offsetHeight;
                    $scope.listHeight = {'height': tablazatFejlecHeight + filterFejlecHeight + 10 + 'px'};
                }
            }
        }
        
        setListHeight();
        window.onresize = function(event) {
           setListHeight();
        };
        $scope.refreshList();
    })